import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

// COMPONENTS
import { Stack } from "@mui/material";
import { usePracticeAnswerClientByApi } from "apis/practiceAnswerApis/usePracticeAnswerApis";
import { usePracticeQuestionClient } from "apis/practiceQuestionApis/usePracticeQuestionApis";
import { actionPracticeScoreFinishApi } from "apis/practiceScoreApis/practiceScoreAction";
import { usePracticeSubjectDetailByApi } from "apis/practiceSubjectApis/usePracticeSubjectApis";
import { StorageKey } from "globals/constants/storage";
import { PageRouteName } from "globals/enums/routes.enum";
import { AnswerChoiceType, LIST_CHOICE_ANSWER } from "globals/types/shares.type";
import useLoadingCallback from "hooks/useLoadingCallback";
import useRoute from "hooks/useRoute";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import { getStorage, removeStorage, setStorage } from "services/storageService";

// ICONS
const ChoiceStartIntro = lazy(() => import("./sections/ChoiceStartIntro"));
const ChoiceStartHead = lazy(() => import("./sections/ChoiceStartHead"));
const ChoiceStartBody = lazy(() => import("./sections/ChoiceStartBody"));

const PracticeChoiceStartPage = () => {
  const { params: { id: practiceSubjectId }, redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const questionNumber = searchParams.get('question') ? Number(searchParams.get('question')) : 0;
  const questionCallApi = Number(questionNumber) - 1;

  const { practiceSubjectDetail } = usePracticeSubjectDetailByApi({ practiceSubjectId });
  const { practiceQuestionClient } = usePracticeQuestionClient({ practiceSubjectId });
  const { answerListClient, isLoadingAnswerClient } = usePracticeAnswerClientByApi({
    practiceQuestionId: !isEmpty(practiceQuestionClient) ? practiceQuestionClient![questionCallApi]?.id : null
  });
  const questionLength = practiceQuestionClient && !isEmpty(practiceQuestionClient) ? practiceQuestionClient?.length : 0;
  const [isActionStart, setIsActionStart] = useState(false);
  const [listChoiceAnswer, setListChoiceAnswer] = useState<AnswerChoiceType[]>([]);
  const [timeCountDown, setTimeCountDown] = useState(120);

  const answerChoiceLength = useMemo(() => {
    return listChoiceAnswer.filter(item => item.answerId).length || 0;
  }, [listChoiceAnswer]);

  const questionCurrent = useMemo(() => {
    if (practiceQuestionClient && !isEmpty(practiceQuestionClient)) {
      return practiceQuestionClient![questionCallApi];
    }
    return null;
  }, [questionCallApi, practiceQuestionClient]);

  const resetPractice = () => {
    setIsActionStart(false);
    setListChoiceAnswer(LIST_CHOICE_ANSWER);
    setTimeCountDown(120);
    removeStorage(StorageKey.listAnswerChoice);
    removeStorage(StorageKey.timeAnswerChoice);
  };

  const handlePrevious = () => {
    if (questionNumber <= 1) {
      resetPractice();
      redirect(`${PageRouteName.PracticeChoiceStartPage}/${practiceSubjectId}`);
    } else {
      redirect(`${PageRouteName.PracticeChoiceStartPage}/${practiceSubjectId}`, {
        question: Number(questionNumber) - 1
      });
    }
  };

  const handleNext = () => {
    redirect(`${PageRouteName.PracticeChoiceStartPage}/${practiceSubjectId}`, {
      question: Number(questionNumber) + 1
    });
  };

  const handleChoice = (answerChoice: AnswerChoiceType) => {
    let newListChoiceAnswer = [...listChoiceAnswer];
    newListChoiceAnswer[answerChoice.id - 1] = answerChoice;
    setStorage(StorageKey.listAnswerChoice, JSON.stringify(newListChoiceAnswer));
    setListChoiceAnswer(newListChoiceAnswer);
  };

  const handleActionStart = () => {
    setIsActionStart(true);
    setListChoiceAnswer(LIST_CHOICE_ANSWER);
    setTimeCountDown(120);
    removeStorage(StorageKey.listAnswerChoice);
    removeStorage(StorageKey.timeAnswerChoice);
  };

  const [handleFinishPractice, isLoadingFinish] = useLoadingCallback(async () => {
    const timeOfTest = 120 - timeCountDown;
    const bodyPayload = {
      listChoiceAnswer,
      timeOfTest,
      practiceSubjectId,
    }
    const id = await actionPracticeScoreFinishApi(bodyPayload);
    if (id) {
      resetPractice();
      redirect(`${PageRouteName.PracticeChoiceResultPage}/${id}`);
    }
  });

  useEffect(() => {
    if (isEmpty(listChoiceAnswer)) {
      const storageAnswerChoice = getStorage(StorageKey.listAnswerChoice) || [];
      const storeTimeChoice = getStorage(StorageKey.timeAnswerChoice) || 120;
      setTimeCountDown(storeTimeChoice as number);
      if (isEmpty(storageAnswerChoice)) {
        setListChoiceAnswer(LIST_CHOICE_ANSWER);
        redirect(`${PageRouteName.PracticeChoiceStartPage}/${practiceSubjectId}`);
      } else {
        setListChoiceAnswer(storageAnswerChoice as AnswerChoiceType[]);
      }
    }
  }, [listChoiceAnswer]);

  useEffect(() => {
    if (!questionNumber) {
      resetPractice();
    }
  }, [questionNumber]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeCountDown > 0) {
        setStorage(StorageKey.timeAnswerChoice, String(timeCountDown - 1));
        setTimeCountDown(timeCountDown - 1);
      } else {
        clearInterval(timer);
        handleFinishPractice();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeCountDown]);

  return (
    <WrapperStyled
      padding={{ xs: '1rem', sm: '2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1rem', sm: '2rem' }}
      justifyContent={{ xs: 'space-between' }}
      alignItems={{ xs: 'center' }}
      height={{ xs: '100%', sm: `calc(100vh - 6rem)`, }}
      position="relative"
    >
      {(!isActionStart && !questionNumber) ? (
        <ChoiceStartIntro
          isAllowTest={!isEmpty(practiceQuestionClient)}
          practiceSubjectDetail={practiceSubjectDetail}
          setIsActionStart={handleActionStart}
        />
      ) : (
        <Suspense>
          <ChoiceStartHead
            answerChoiceLength={answerChoiceLength}
            timeCountDown={timeCountDown}
          />
          <ChoiceStartBody
            answerChoice={listChoiceAnswer[questionNumber - 1]}
            questionLength={questionLength}
            questionCurrent={questionCurrent}
            questionNumber={questionNumber}
            onPrevious={handlePrevious}
            onNext={handleNext}
            onChoice={handleChoice}
            answerListClient={answerListClient}
            handleFinishPractice={handleFinishPractice}
            isLoadingAnswerClient={isLoadingAnswerClient}
          />
        </Suspense>
      )}
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;


export default PracticeChoiceStartPage;
