import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import {
  actionPracticeSubjectDetailApi,
  actionPracticeSubjectListApi,
} from "./practiceSubjectAction";
import { FetchPracticeSubjectParams } from "./practiceSubjectParam";
import { usePracticeSubjectActionsContext, usePracticeSubjectDataContext } from "./practiceSubjectContext";

export const usePracticeSubjectListByApi = ({
  searchName,
  page,
}: FetchPracticeSubjectParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();
  const { updatePracticeSubjectList } = usePracticeSubjectActionsContext();
  const { practiceSubjectList } = usePracticeSubjectDataContext();

  const fetchPracticeSubjectListByApi = async () => {
    await abortRequest();
    const data = await actionPracticeSubjectListApi({
      searchName,
      page,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updatePracticeSubjectList(data!);
    }
  };

  // Unmount Apis
  useEffect(() => {
    if (isEmpty(practiceSubjectList) || searchName || page) {
      fetchPracticeSubjectListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    practiceSubjectList,
    fetchPracticeSubjectListByApi,
  };
};

export const usePracticeSubjectDetailByApi = ({
  practiceSubjectId,
}: {
  practiceSubjectId: number;
}) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();
  const { updatePracticeSubjectDetail } = usePracticeSubjectActionsContext();
  const { practiceSubjectDetail } = usePracticeSubjectDataContext();

  const fetchPracticeSubjectDetailByApi = async () => {
    await abortRequest();
    const data = await actionPracticeSubjectDetailApi({
      practiceSubjectId,
      newAbortSignal,
    });
    updatePracticeSubjectDetail(data!);
  };

  // Unmount Apis
  useEffect(() => {
    if (practiceSubjectId) {
      fetchPracticeSubjectDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceSubjectId]);

  return {
    practiceSubjectDetail: practiceSubjectId ? practiceSubjectDetail : null,
    fetchPracticeSubjectDetailByApi,
  };
};
