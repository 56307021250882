import { CourseCategoryAllDto, CourseCategoryListDto } from "models/classes/courseCategory.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** Context cho danh sách danh mục khóa học */
const CourseCategoryListContext = createContext<{
  courseCategoryList: CourseCategoryListDto[];
  updateCourseCategoryList: (data: CourseCategoryListDto[]) => void;
}>({
  courseCategoryList: [],
  updateCourseCategoryList: () => { },
});

/** Context cho toàn bộ danh mục khóa học */
const CourseCategoryAllContext = createContext<{
  courseCategoryAll: CourseCategoryAllDto[];
  updateCourseCategoryAll: (data: CourseCategoryAllDto[]) => void;
}>({
  courseCategoryAll: [],
  updateCourseCategoryAll: () => { },
});

/** Context cho chi tiết danh mục khóa học */
const CategoryDetailContext = createContext<{
  categoryDetail: CourseCategoryAllDto | null;
  updateCategoryDetail: (data: CourseCategoryAllDto) => void;
}>({
  categoryDetail: null,
  updateCategoryDetail: () => { },
});

/** Provider tổng hợp */
export const CourseCategoryProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseCategoryList, setCourseCategoryList] = useState<CourseCategoryListDto[]>([]);
  const [courseCategoryAll, setCourseCategoryAll] = useState<CourseCategoryAllDto[]>([]);
  const [categoryDetail, setCategoryDetail] = useState<CourseCategoryAllDto | null>(null);

  const updateCourseCategoryList = (data: CourseCategoryListDto[]) => {
    setCourseCategoryList(data);
  };

  const updateCourseCategoryAll = (data: CourseCategoryAllDto[]) => {
    setCourseCategoryAll(data);
  };

  const updateCategoryDetail = (data: CourseCategoryAllDto) => {
    setCategoryDetail(data);
  };

  return (
    <CourseCategoryListContext.Provider value={{ courseCategoryList, updateCourseCategoryList }}>
      <CourseCategoryAllContext.Provider value={{ courseCategoryAll, updateCourseCategoryAll }}>
        <CategoryDetailContext.Provider value={{ categoryDetail, updateCategoryDetail }}>
          {children}
        </CategoryDetailContext.Provider>
      </CourseCategoryAllContext.Provider>
    </CourseCategoryListContext.Provider>
  );
};

/** Custom hooks */
export const useCourseCategoryListContext = () => useContext(CourseCategoryListContext);
export const useCourseCategoryAllContext = () => useContext(CourseCategoryAllContext);
export const useCategoryDetailContext = () => useContext(CategoryDetailContext);
