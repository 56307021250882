import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

const AdminContact = React.memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <WrapperStyled
      width={{ xs: '-webkit-fill-available' }}
      height={{ xs: 150 }}
      flexDirection="column"
      justifyContent="center"
      bgcolor={theme.palette.primary.light}
      rowGap={{ xs: '1rem' }}
      padding={{ xs: '1rem', sm: '2rem' }}
      borderRadius={3}
    >
      <Box
        fontSize={{ xs: '20px' }}
        fontWeight={{ xs: '500' }}
        textTransform={{ xs: 'uppercase' }}
        color={theme.palette.text.light}
      >
        {t('Admin information')} 👇
      </Box>
      <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
        ✅ {t('Admin')}: Nguyen Kim Tien
      </Box>
      <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
        ✅ {t('Zalo')}: 0763557366
      </Box>
      <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
        ✅ {t('Social')}: <a
          href={'https://www.facebook.com/nkt.9920/'}
          target="_blank"
          style={{ color: theme.palette.text.light, textDecoration: 'none' }}
        >
          👉 {t('View now')}
        </a>
      </Box>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default AdminContact;
