import { CourseDescriptionByCourseDetailDto, CourseDescriptionListRes } from "models/classes/courseDescription.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** Context cho danh sách mô tả khóa học */
const CourseDescriptionListContext = createContext<{
  courseDescriptionListRes: CourseDescriptionListRes | null;
  updateCourseDescriptionListRes: (data: CourseDescriptionListRes) => void;
}>({
  courseDescriptionListRes: null,
  updateCourseDescriptionListRes: () => { },
});

/** Context cho chi tiết mô tả khóa học */
const CourseDescriptionDetailContext = createContext<{
  courseDescription: CourseDescriptionByCourseDetailDto | null;
  updateCourseDescription: (data: CourseDescriptionByCourseDetailDto) => void;
}>({
  courseDescription: null,
  updateCourseDescription: () => { },
});

/** Provider tổng hợp */
export const CourseDescriptionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseDescriptionListRes, setCourseDescriptionListRes] = useState<CourseDescriptionListRes | null>(null);
  const [courseDescription, setCourseDescription] = useState<CourseDescriptionByCourseDetailDto | null>(null);

  const updateCourseDescriptionListRes = (data: CourseDescriptionListRes) => {
    setCourseDescriptionListRes(data);
  };

  const updateCourseDescription = (data: CourseDescriptionByCourseDetailDto) => {
    setCourseDescription(data);
  };

  return (
    <CourseDescriptionListContext.Provider value={{ courseDescriptionListRes, updateCourseDescriptionListRes }}>
      <CourseDescriptionDetailContext.Provider value={{ courseDescription, updateCourseDescription }}>
        {children}
      </CourseDescriptionDetailContext.Provider>
    </CourseDescriptionListContext.Provider>
  );
};

/** Custom hooks */
export const useCourseDescriptionListContext = () => useContext(CourseDescriptionListContext);
export const useCourseDescriptionDetailContext = () => useContext(CourseDescriptionDetailContext);
