import BaseModel from "./base.class";
import { PracticeSubjectDto } from "./practiceSubject.class";

export class PracticeScoreDto extends BaseModel<PracticeScoreDto> {
  id?: number;
  score?: number;
  numberOfTrue?: number;
  timeOfTest?: number;
  dateOfTest?: string;
  userId?: number;
  practiceSubjectId?: number;
  practiceSubject?: PracticeSubjectDto;
  nameUser?: string;
  emailUser?: string;
}

export type PracticeScoreListRes = {
  list: PracticeScoreDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type PracticeScoreData = {
  name: string;
  practiceSubjectId: number;
};
