export enum DevelopDebugKey {
  DisableCheckStep = "DEV_DISABLE_CHECK_STEP",
}

export enum StorageKey {
  Language = "lang",
  AccessToken = "token",
  ExpiredTime = "expiredTime",
  Role = "role",
  ThemeColor = "themeColor",
  listAnswerChoice = "listAnswerChoice",
  timeAnswerChoice = "timeAnswerChoice",
}

export type StorageValues = {
  [StorageKey.Language]?: string;
  [StorageKey.AccessToken]?: string;
  [StorageKey.ExpiredTime]?: string;
  [StorageKey.Role]?: string;
  [StorageKey.ThemeColor]?: string;
  [StorageKey.listAnswerChoice]?: string;
  [StorageKey.timeAnswerChoice]?: string;
};

export enum SessionKey {
  Language = "lang",
  AccessToken = "accessToken",
  TableParams = "tableParams",
  isInitialLoading = "isInitialLoading",
}

export type SessionValues = {
  [SessionKey.Language]?: string;
  [SessionKey.AccessToken]?: string;
  [SessionKey.TableParams]?: object;
  [SessionKey.isInitialLoading]?: string;
};

export enum Language {
  EN = "en",
  VI = "vi",
}
