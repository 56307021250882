import useAbortRequest from "hooks/useAbortRequest";
import useLoadingCallback from "hooks/useLoadingCallback";
import { isEmpty } from "lodash";
import {
  PracticeAnswerClientDto
} from "models/classes/practiceAnswer.class";
import { useEffect, useState } from "react";
import {
  actionPracticeAnswerClientApi,
  actionPracticeAnswerDetailApi,
  actionPracticeAnswerListApi,
} from "./practiceAnswerAction";
import { FetchPracticeAnswerParams } from "./practiceAnswerParam";
import { usePracticeAnswerActionsContext, usePracticeAnswerDataContext } from "./practiceAnswerContext";

export const usePracticeAnswerListByApi = ({
  searchName,
  page,
}: FetchPracticeAnswerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();
  const { updatePracticeAnswerList } = usePracticeAnswerActionsContext();
  const { practiceAnswerList } = usePracticeAnswerDataContext();

  const fetchPracticeAnswerListByApi = async () => {
    await abortRequest();
    const data = await actionPracticeAnswerListApi({
      searchName,
      page,
      newAbortSignal,
    });
    updatePracticeAnswerList(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (isEmpty(practiceAnswerList) || searchName || page) {
      fetchPracticeAnswerListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    practiceAnswerList,
    fetchPracticeAnswerListByApi,
  };
};

export const usePracticeAnswerDetailByApi = ({
  practiceAnswerId,
}: FetchPracticeAnswerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();
  const { updatePracticeAnswerDetail } = usePracticeAnswerActionsContext();
  const { practiceAnswerDetail } = usePracticeAnswerDataContext();

  const fetchPracticeAnswerDetailByApi = async () => {
    await abortRequest();
    const data = await actionPracticeAnswerDetailApi({
      practiceAnswerId,
      newAbortSignal,
    });
    updatePracticeAnswerDetail(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (practiceAnswerId) {
      fetchPracticeAnswerDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceAnswerId]);

  return {
    practiceAnswerDetail: practiceAnswerId ? practiceAnswerDetail : null,
    fetchPracticeAnswerDetailByApi,
  };
};

export const usePracticeAnswerClientByApi = ({
  practiceQuestionId,
}: FetchPracticeAnswerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const [answerListClient, setAnswerListClient] = useState<
    PracticeAnswerClientDto[]
  >([]);

  const [fetchPracticeAnswerClient, isLoadingAnswerClient] = useLoadingCallback(
    async () => {
      await abortRequest();
      const data = await actionPracticeAnswerClientApi({
        practiceQuestionId,
        newAbortSignal,
      });
      setAnswerListClient(data!);
    }
  );

  // Unmount APIs
  useEffect(() => {
    if (practiceQuestionId) {
      fetchPracticeAnswerClient();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceQuestionId]);

  return {
    answerListClient,
    isLoadingAnswerClient,
    fetchPracticeAnswerClient,
  };
};
