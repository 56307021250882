import { AnswerDto, AnswerListRes } from "models/classes/answer.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

/** Context cho danh sách câu trả lời */
const AnswerListContext = createContext<{
  answerListRes: AnswerListRes | null;
  updateAnswerList: (answerListRes: AnswerListRes) => void;
}>({
  answerListRes: null,
  updateAnswerList: () => {},
});

/** Context cho chi tiết câu trả lời */
const AnswerDetailContext = createContext<{
  answerDetail: AnswerDto | null;
  updateAnswerDetail: (answerDetail: AnswerDto) => void;
}>({
  answerDetail: null,
  updateAnswerDetail: () => {},
});

export const AnswerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [answerListRes, setAnswerListRes] = useState<AnswerListRes | null>(null);
  const [answerDetail, setAnswerDetail] = useState<AnswerDto | null>(null);

  const updateAnswerList = (answerListRes: AnswerListRes) => {
    setAnswerListRes(answerListRes);
  };

  const updateAnswerDetail = (answerDetail: AnswerDto) => {
    setAnswerDetail(answerDetail);
  };

  return (
    <AnswerListContext.Provider value={{ answerListRes, updateAnswerList }}>
      <AnswerDetailContext.Provider value={{ answerDetail, updateAnswerDetail }}>
        {children}
      </AnswerDetailContext.Provider>
    </AnswerListContext.Provider>
  );
};

/** Custom hooks */
export const useAnswerListContext = () => useContext(AnswerListContext);
export const useAnswerDetailContext = () => useContext(AnswerDetailContext);
