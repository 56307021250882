import { isEmpty } from "lodash";
import { useEffect } from "react";

// APIS

// HOOKS
import useAbortRequest from "hooks/useAbortRequest";
import {
  actionCourseCategoryAllApi,
  actionCourseCategoryDetailApi,
  actionCourseCategoryListApi,
} from "./courseCategoryAction";
import { useCategoryDetailContext, useCourseCategoryAllContext, useCourseCategoryListContext } from "./courseCategoryContext";
import { FetchCourseCategoryParams } from "./courseCategoryParam";

// Course Personal News
export const useCourseCategoryListByApi = () => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseCategoryList, updateCourseCategoryList } =
    useCourseCategoryListContext();

  const fetchCourseCategoryListByApi = async () => {
    await abortRequest();
    const data = await actionCourseCategoryListApi({
      newAbortSignal,
    });
    updateCourseCategoryList(data);
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(courseCategoryList)) {
      fetchCourseCategoryListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    courseCategoryList,
    fetchCourseCategoryListByApi,
  };
};

// Course Personal All
export const useCourseCategoryAllByApi = () => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseCategoryAll, updateCourseCategoryAll } =
    useCourseCategoryAllContext();

  const fetchCourseCategoryAllByApi = async () => {
    await abortRequest();
    const data = await actionCourseCategoryAllApi({
      newAbortSignal,
    });
    updateCourseCategoryAll(data);
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(courseCategoryAll)) {
      fetchCourseCategoryAllByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    courseCategoryAll,
    fetchCourseCategoryAllByApi,
  };
};

export const useCategoryDetailByApi = ({
  courseCategoryId,
}: FetchCourseCategoryParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { categoryDetail, updateCategoryDetail } = useCategoryDetailContext();

  const fetchCategoryDetailByApi = async () => {
    await abortRequest();
    const data = await actionCourseCategoryDetailApi({
      courseCategoryId,
      newAbortSignal,
    });
    updateCategoryDetail(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (courseCategoryId) {
      fetchCategoryDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [courseCategoryId]);

  return {
    categoryDetail: courseCategoryId ? categoryDetail : null,
    fetchCategoryDetailByApi,
  };
};
