import { isEmpty } from "lodash";
import {
  PracticeAnswerClientDto,
  PracticeAnswerData,
  PracticeAnswerDto,
} from "models/classes/practiceAnswer.class";
import {
  fetchPracticeAnswerClientApi,
  fetchPracticeAnswerCreateApi,
  fetchPracticeAnswerDeleteApi,
  fetchPracticeAnswerDetailApi,
  fetchPracticeAnswerListApi,
  fetchPracticeAnswerUpdateApi,
} from "./practiceAnswerFetch";
import { FetchPracticeAnswerParams } from "./practiceAnswerParam";

export const actionPracticeAnswerListApi = async ({
  ...params
}: FetchPracticeAnswerParams) => {
  try {
    const { data } = await fetchPracticeAnswerListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: PracticeAnswerDto) =>
          new PracticeAnswerDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeAnswerCreateApi = async (
  body: PracticeAnswerData
) => {
  try {
    const { data } = await fetchPracticeAnswerCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeAnswerUpdateApi = async (
  id: number,
  body: PracticeAnswerData
) => {
  try {
    const { data } = await fetchPracticeAnswerUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeAnswerDetailApi = async ({
  ...params
}: FetchPracticeAnswerParams) => {
  try {
    const { data } = await fetchPracticeAnswerDetailApi(params);
    if (!isEmpty(data)) {
      return new PracticeAnswerDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeAnswerDeleteApi = async ({
  ...params
}: FetchPracticeAnswerParams) => {
  try {
    const { data } = await fetchPracticeAnswerDeleteApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeAnswerClientApi = async ({
  ...params
}: FetchPracticeAnswerParams) => {
  try {
    const { data } = await fetchPracticeAnswerClientApi(params);
    if (!isEmpty(data)) {
      return data.map((item: PracticeAnswerClientDto) =>
        new PracticeAnswerClientDto().fromPayload(item)
      );
    }
  } catch (error) {
    console.log(error);
  }
};
