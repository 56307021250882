import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { PracticeQuestionData } from "models/classes/practiceQuestion.class";
import { FetchPracticeQuestionParams } from "./practiceQuestionParam";

export const fetchPracticeQuestionListApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchPracticeQuestionParams) =>
  publicRequest.get(
    `/practiceQuestions/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchPracticeQuestionClientApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchPracticeQuestionParams) =>
  publicRequest.get(
    `/practiceQuestions/listClient`,
    parseRequestParams(params, { searchName, newAbortSignal }, true)
  ) as any;

export const fetchPracticeQuestionCreateApi = async (
  body: PracticeQuestionData
) => publicRequest.post(`/practiceQuestions/create`, body) as any;

export const fetchPracticeQuestionUpdateApi = async (
  id: number,
  body: PracticeQuestionData
) => publicRequest.put(`/practiceQuestions/update/${id}`, body) as any;

export const fetchPracticeQuestionDetailApi = async ({
  practiceQuestionId,
  newAbortSignal,
  ...params
}: FetchPracticeQuestionParams) =>
  publicRequest.get(
    `/practiceQuestions/detail/${practiceQuestionId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchPracticeQuestionDeleteApi = async ({
  practiceQuestionId,
}: FetchPracticeQuestionParams) =>
  publicRequest.delete(
    `/practiceQuestions/delete/${practiceQuestionId}`
  ) as any;
