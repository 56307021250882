import moment from "moment";

export const formatCurrencyNumberWithDecimal = (value: string) => {
  if (value) {
    const cleanedValue = String(value).replace(/,/g, "");
    const parts = cleanedValue.split(".");
    const integerPart = parts[0].replace(/\D/g, "");
    const decimalPart = parts[1] ? parts[1].replace(/\D/g, "").slice(0, 4) : "";

    let formattedValue = "";

    if (integerPart) {
      formattedValue = parseFloat(integerPart).toLocaleString("en-US");
    }

    if (decimalPart) {
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue || 0;
  }
};

export const checkWidthLessThan768 = () => {
  return window.innerWidth <= 768;
};

export const regexNumber = /^\d+$/;

export const listFileType = [
  "image/webp",
  "image/jpg",
  "image/jpeg",
  "image/png",
];

// 1 MB
const maxSizeInBytes = 1 * 1024 * 1024;

export const validationFile = (input: any) => {
  const fileType = input.type;
  return listFileType.includes(fileType);
};

export const validationSize = (input: any) => {
  const fileSize = input.size;
  return fileSize <= maxSizeInBytes;
};

export const generateTime = (date: number | string) => {
  return String(
    moment(
      new Date(date).toLocaleString("en-US", { timeZone: "Asia/Jakarta" })
    ).format("DD/MM/YYYY HH:mm:ss")
  );
};

export const generateCurrent = (date: number | string) => {
  return String(
    moment(
      new Date(date).toLocaleString("en-US", { timeZone: "Asia/Jakarta" })
    ).format("DD/MM/YYYY")
  );
};

export const generateUnixTime = (timestamp: string) => {
  const date = new Date(parseInt(timestamp, 10));

  const day = date.getDate().toString().padStart(2, "0"); // Lấy ngày, thêm số 0 nếu cần
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng (0-11) nên +1
  const year = date.getFullYear(); // Lấy năm
  const hours = date.getHours().toString().padStart(2, "0"); // Lấy giờ
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Lấy phút
  const seconds = date.getSeconds().toString().padStart(2, "0"); // Lấy giây

  // return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  return `${day}/${month}/${year}`;
};

export const generateAtTime30Day = () => {
  const startDate = new Date();
  const endDate = Number(startDate.getTime()) + 2592000000;
  return endDate.toString();
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60); // Tính phút
  const seconds = time % 60; // Tính giây
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`; // Format mm:ss
};
