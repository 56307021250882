import BaseModel from "./base.class";
import { PracticeDto } from "./practice.class";
import { PracticeQuestionDto } from "./practiceQuestion.class";

export class PracticeSubjectDto extends BaseModel<PracticeSubjectDto> {
  id?: number;
  name?: string;
  content?: string;
  body?: string;
  image?: string;
  practiceId?: number;
  practice?: PracticeDto;
  practiceQuestions?: PracticeQuestionDto[];
}

export type PracticeSubjectListRes = {
  list: PracticeSubjectDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type PracticeSubjectData = {
  name: string;
  content: string;
  body: string;
  image: string;
  practiceId: number;
};
