import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchAuthParams } from "./authParam";
import { UserInfoReq } from "models/classes/auth.class";

export const fetchAuthSignInApi = async ({ ...params }: FetchAuthParams) =>
  publicRequest.post(`/auth/signin`, params) as any;

export const fetchAuthUpdateProfileApi = async ({
  ...params
}: FetchAuthParams) => publicRequest.put(`/auth/profile`, params) as any;

export const fetchAuthUpdatePasswordApi = async ({
  ...params
}: FetchAuthParams) => publicRequest.put(`/auth/password`, params) as any;

export const fetchAuthDetailProfileApi = async ({
  newAbortSignal,
  ...params
}: FetchAuthParams) =>
  publicRequest.get(
    `/auth/profile`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchAuthSignUpApi = async ({ ...params }: FetchAuthParams) =>
  publicRequest.post(`/auth/signup`, params) as any;

export const fetchUserListResApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchAuthParams) =>
  publicRequest.get(
    `/auth/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchResetPassowrdApi = async (body: UserInfoReq) =>
  publicRequest.put("/auth/admin/password", body) as any;

export const fetchUpdateByAdminApi = async (body: UserInfoReq) =>
  publicRequest.put("/auth/admin/update", body) as any;

export const fetchIsMembershipApi = async () =>
  publicRequest.get("/auth/check-membership") as any;