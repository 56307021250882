export type IconType = {
  width?: string;
  height?: string;
  color?: string;
};

export type InterviewDashboardType = {
  name: string;
  level: string;
  color: string;
  description?: string;
};

export type AnswerChoiceType = {
  id: number;
  name: string;
  questionId: number | null;
  answerId: number | null;
};

export const LIST_CHOICE_ANSWER: AnswerChoiceType[] = [
  { id: 1, name: "", questionId: null, answerId: null },
  { id: 2, name: "", questionId: null, answerId: null },
  { id: 3, name: "", questionId: null, answerId: null },
  { id: 4, name: "", questionId: null, answerId: null },
  { id: 5, name: "", questionId: null, answerId: null },
  { id: 6, name: "", questionId: null, answerId: null },
  { id: 7, name: "", questionId: null, answerId: null },
  { id: 8, name: "", questionId: null, answerId: null },
  { id: 9, name: "", questionId: null, answerId: null },
  { id: 10, name: "", questionId: null, answerId: null },
  { id: 11, name: "", questionId: null, answerId: null },
  { id: 12, name: "", questionId: null, answerId: null },
  { id: 13, name: "", questionId: null, answerId: null },
  { id: 14, name: "", questionId: null, answerId: null },
  { id: 15, name: "", questionId: null, answerId: null },
  { id: 16, name: "", questionId: null, answerId: null },
  { id: 17, name: "", questionId: null, answerId: null },
  { id: 18, name: "", questionId: null, answerId: null },
  { id: 19, name: "", questionId: null, answerId: null },
  { id: 20, name: "", questionId: null, answerId: null },
];
