import {
  InterviewCategoryDto,
  InterviewCategoryListRes
} from "models/classes/interviewCategory.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

/** Tạo từng context riêng biệt */
const InterviewCategoryListContext = createContext<InterviewCategoryListRes | null>(null);
const InterviewClientListContext = createContext<InterviewCategoryListRes | null>(null);
const InterviewCategoryDetailContext = createContext<InterviewCategoryDto | null>(null);

const UpdateInterviewCategoryListContext = createContext<(data: InterviewCategoryListRes) => void>(() => { });
const UpdateInterviewClientListContext = createContext<(data: InterviewCategoryListRes) => void>(() => { });
const UpdateInterviewCategoryDetailContext = createContext<(data: InterviewCategoryDto) => void>(() => { });

export const InterviewCategoryProvider = ({ children }: PropsWithChildren<{}>) => {
  const [interviewCategoryListRes, setInterviewCategoryListRes] = useState<InterviewCategoryListRes | null>(null);
  const [interviewClientListRes, setInterviewClientListRes] = useState<InterviewCategoryListRes | null>(null);
  const [interviewCategoryDetail, setInterviewCategoryDetail] = useState<InterviewCategoryDto | null>(null);

  return (
    <InterviewCategoryListContext.Provider value={interviewCategoryListRes}>
      <UpdateInterviewCategoryListContext.Provider value={setInterviewCategoryListRes}>
        <InterviewClientListContext.Provider value={interviewClientListRes}>
          <UpdateInterviewClientListContext.Provider value={setInterviewClientListRes}>
            <InterviewCategoryDetailContext.Provider value={interviewCategoryDetail}>
              <UpdateInterviewCategoryDetailContext.Provider value={setInterviewCategoryDetail}>
                {children}
              </UpdateInterviewCategoryDetailContext.Provider>
            </InterviewCategoryDetailContext.Provider>
          </UpdateInterviewClientListContext.Provider>
        </InterviewClientListContext.Provider>
      </UpdateInterviewCategoryListContext.Provider>
    </InterviewCategoryListContext.Provider>
  );
};

/** Custom hooks để sử dụng context */
export const useInterviewCategoryListContext = () => useContext(InterviewCategoryListContext);
export const useUpdateInterviewCategoryListContext = () => useContext(UpdateInterviewCategoryListContext);

export const useInterviewClientListContext = () => useContext(InterviewClientListContext);
export const useUpdateInterviewClientListContext = () => useContext(UpdateInterviewClientListContext);

export const useInterviewCategoryDetailContext = () => useContext(InterviewCategoryDetailContext);
export const useUpdateInterviewCategoryDetailContext = () => useContext(UpdateInterviewCategoryDetailContext);
