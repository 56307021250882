import { lazy, Suspense, useState } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";

// COMPONENTS
import { Stack, useTheme } from "@mui/material";
import { useAnswerClientDetailByApi } from "apis/answerApis/useAnswerApis";
import { useQuestionClientByApi } from "apis/questionApis/useQuestionApis";
import DialogSlide from "components/modals/DialogSlide";
import { PageRouteName } from "globals/enums/routes.enum";
import { isEmpty } from "lodash";
import { QuestionSearchType } from "models/classes/question.class";
import { useTranslation } from "react-i18next";
import ContentJoin from "views/ContentJoin";

// CONSTANTS

// LAZY
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const QuestionList = lazy(() => import("./sections/QuestionList"));
const QuestionSearch = lazy(() => import("./sections/QuestionSearch"));

const InterviewDetailPage = () => {
  // instances
  const theme = useTheme();
  const { t } = useTranslation();
  const { params: { id: interviewCategoryId }, redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const selectedLevel = searchParams.get('level') ? String(searchParams.get('level')) : '';

  // states
  const [isDialogResult, setIsDialogResult] = useState(false);
  const [questionId, setQuestionId] = useState(0);

  // hooks
  const { questionClientRes } = useQuestionClientByApi({
    searchName,
    page,
    level: selectedLevel,
    interviewCategoryId,
  });
  const { answerDetail } = useAnswerClientDetailByApi({
    questionId
  })

  const handleViewResult = (questionId: number) => {
    setQuestionId(questionId);
    setIsDialogResult(true);
  };

  return (
    <WrapperStyled
      padding={{ xs: '2rem 1rem', sm: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '2rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Interview"
          subTitle="Choose your favorite interview question!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <QuestionSearch
          title="Search question"
          searchName={searchName}
          selectedLevel={selectedLevel}
          onSubmitSearch={(questionSearch: QuestionSearchType) =>
            redirect(`${PageRouteName.InterviewDetailPage}/${interviewCategoryId}`, {
              page: 1, name: questionSearch.searchName, level: questionSearch.selectedLevel
            })
          }
        />
        <QuestionList
          questionClientRes={questionClientRes}
          page={page}
          handleViewResult={handleViewResult}
          setPage={(page) => redirect(`${PageRouteName.InterviewDetailPage}/${interviewCategoryId}`, {
            page, name: searchName, level: selectedLevel
          })}
        />

        <DialogSlide
          label="Answer"
          open={isDialogResult}
          isClose={false}
          onClose={(value) => setIsDialogResult(value)}
          onConfirm={() => setIsDialogResult(false)}
        >
          <Stack
            flexDirection="column"
            color={theme.palette.text.light}
          >
            {!isEmpty(answerDetail) && (
              <div dangerouslySetInnerHTML={{ __html: answerDetail?.description! || '' }}></div>
            )}
            {isEmpty(answerDetail) && (
              <ContentJoin />
            )}
          </Stack>
        </DialogSlide>
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;

export default InterviewDetailPage;
