import { PracticeDto, PracticeListRes } from "models/classes/practice.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** Context chứa dữ liệu */
const PracticeDataContext = createContext<{
  practiceList: PracticeListRes | null;
  practiceDetail: PracticeDto | null;
}>({
  practiceList: null,
  practiceDetail: null,
});

/** Context chứa các hàm cập nhật */
const PracticeActionsContext = createContext<{
  updatePracticeList: (data: PracticeListRes) => void;
  updatePracticeDetail: (data: PracticeDto) => void;
}>({
  updatePracticeList: () => { },
  updatePracticeDetail: () => { },
});

/** Provider tổng hợp */
export const PracticeProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceList, setPracticeList] = useState<PracticeListRes | null>(null);
  const [practiceDetail, setPracticeDetail] = useState<PracticeDto | null>(null);

  return (
    <PracticeDataContext.Provider value={{ practiceList, practiceDetail }}>
      <PracticeActionsContext.Provider value={{
        updatePracticeList: setPracticeList,
        updatePracticeDetail: setPracticeDetail
      }}>
        {children}
      </PracticeActionsContext.Provider>
    </PracticeDataContext.Provider>
  );
};

/** Custom hooks */
export const usePracticeDataContext = () => useContext(PracticeDataContext);
export const usePracticeActionsContext = () => useContext(PracticeActionsContext);
