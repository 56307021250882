import BaseModel from "./base.class";
import { PracticeAnswerDto } from "./practiceAnswer.class";

export class PracticeQuestionDto extends BaseModel<PracticeQuestionDto> {
  id?: number;
  name?: string;
  practiceSubjectId?: number;
  practiceAnswers?: PracticeAnswerDto[];
}

export type PracticeQuestionListRes = {
  list: PracticeQuestionDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type PracticeQuestionData = {
  name: string;
  practiceSubjectId: number;
};
