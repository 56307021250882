import {
  CoursePersonalDetailDto,
  CoursePersonalListRes,
  CoursePersonalNewsDto,
  CoursePersonalVipsDto
} from "models/classes/coursePersonal.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** Context quản lý danh sách Course Personal */
const CoursePersonalListContext = createContext<{
  coursePersonalNews: CoursePersonalNewsDto[];
  coursePersonalVips: CoursePersonalVipsDto[];
  coursePersonalClientListRes: CoursePersonalListRes | null;
  coursePersonalTeacherListRes: CoursePersonalListRes | null;
  updateCoursePersonalNews: (data: CoursePersonalNewsDto[]) => void;
  updateCoursePersonalVips: (data: CoursePersonalVipsDto[]) => void;
  updateCoursePersonalClientList: (data: CoursePersonalListRes) => void;
  updateCoursePersonalTeacherList: (data: CoursePersonalListRes) => void;
}>({
  coursePersonalNews: [],
  coursePersonalVips: [],
  coursePersonalClientListRes: null,
  coursePersonalTeacherListRes: null,
  updateCoursePersonalNews: () => { },
  updateCoursePersonalVips: () => { },
  updateCoursePersonalClientList: () => { },
  updateCoursePersonalTeacherList: () => { },
});

/** Context quản lý chi tiết Course Personal */
const CoursePersonalDetailContext = createContext<{
  coursePersonalDetail: CoursePersonalDetailDto | null;
  updateCoursePersonalDetail: (data: CoursePersonalDetailDto | null) => void;
}>({
  coursePersonalDetail: null,
  updateCoursePersonalDetail: () => { },
});

/** Provider tổng hợp */
export const CoursePersonalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [coursePersonalNews, setCoursePersonalNews] = useState<CoursePersonalNewsDto[]>([]);
  const [coursePersonalVips, setCoursePersonalVips] = useState<CoursePersonalVipsDto[]>([]);
  const [coursePersonalClientListRes, setCoursePersonalClientListRes] = useState<CoursePersonalListRes | null>(null);
  const [coursePersonalTeacherListRes, setCoursePersonalTeacherListRes] = useState<CoursePersonalListRes | null>(null);
  const [coursePersonalDetail, setCoursePersonalDetail] = useState<CoursePersonalDetailDto | null>(null);

  return (
    <CoursePersonalListContext.Provider
      value={{
        coursePersonalNews,
        coursePersonalVips,
        coursePersonalClientListRes,
        coursePersonalTeacherListRes,
        updateCoursePersonalNews: setCoursePersonalNews,
        updateCoursePersonalVips: setCoursePersonalVips,
        updateCoursePersonalClientList: setCoursePersonalClientListRes,
        updateCoursePersonalTeacherList: setCoursePersonalTeacherListRes
      }}
    >
      <CoursePersonalDetailContext.Provider
        value={{
          coursePersonalDetail,
          updateCoursePersonalDetail: setCoursePersonalDetail,
        }}
      >
        {children}
      </CoursePersonalDetailContext.Provider>
    </CoursePersonalListContext.Provider>
  );
};

/** Custom hooks */
export const useCoursePersonalListContext = () => useContext(CoursePersonalListContext);
export const useCoursePersonalDetailContext = () => useContext(CoursePersonalDetailContext);
