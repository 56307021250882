import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import {
  FetchPracticeScoreParams,
  PracticeScoreFinishData,
} from "./practiceScoreParam";

export const fetchPracticeScoreListApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchPracticeScoreParams) =>
  publicRequest.get(
    `/practiceScores/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchPracticeScoreListClientApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchPracticeScoreParams) =>
  publicRequest.get(
    `/practiceScores/list/client`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchPracticeScoreTopRankApi = async ({
  practiceSubjectId,
}: FetchPracticeScoreParams) =>
  publicRequest.get(`/practiceScores/top/${practiceSubjectId}`) as any;

export const fetchPracticeScoreFinishApi = async (
  body: PracticeScoreFinishData
) => publicRequest.post(`/practiceScores/finish`, body) as any;

export const fetchPracticeScoreDetailClientApi = async ({
  practiceScoreId,
  newAbortSignal,
  ...params
}: FetchPracticeScoreParams) =>
  publicRequest.get(
    `/practiceScores/detail/client/${practiceScoreId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchPracticeScoreDeleteByIdApi = async ({
  practiceScoreId,
}: FetchPracticeScoreParams) =>
  publicRequest.delete(`/practiceScores/delete/${practiceScoreId}`) as any;
