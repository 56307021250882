import { PracticeSubjectDto, PracticeSubjectListRes } from "models/classes/practiceSubject.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** 📌 Context chứa dữ liệu */
const PracticeSubjectDataContext = createContext<{
  practiceSubjectList: PracticeSubjectListRes | null;
  practiceSubjectDetail: PracticeSubjectDto | null;
}>({
  practiceSubjectList: null,
  practiceSubjectDetail: null,
});

/** 📌 Context chứa các actions */
const PracticeSubjectActionsContext = createContext<{
  updatePracticeSubjectList: (data: PracticeSubjectListRes) => void;
  updatePracticeSubjectDetail: (data: PracticeSubjectDto) => void;
}>({
  updatePracticeSubjectList: () => { },
  updatePracticeSubjectDetail: () => { },
});

/** 📌 Provider tổng hợp */
export const PracticeSubjectProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceSubjectList, setPracticeSubjectList] = useState<PracticeSubjectListRes | null>(null);
  const [practiceSubjectDetail, setPracticeSubjectDetail] = useState<PracticeSubjectDto | null>(null);

  return (
    <PracticeSubjectDataContext.Provider value={{ practiceSubjectList, practiceSubjectDetail }}>
      <PracticeSubjectActionsContext.Provider
        value={{
          updatePracticeSubjectList: setPracticeSubjectList,
          updatePracticeSubjectDetail: setPracticeSubjectDetail,
        }}
      >
        {children}
      </PracticeSubjectActionsContext.Provider>
    </PracticeSubjectDataContext.Provider>
  );
};

/** 📌 Custom hooks */
export const usePracticeSubjectDataContext = () => useContext(PracticeSubjectDataContext);
export const usePracticeSubjectActionsContext = () => useContext(PracticeSubjectActionsContext);
