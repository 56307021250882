import { PracticeQuestionDto, PracticeQuestionListRes } from "models/classes/practiceQuestion.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** 📌 Context chứa dữ liệu */
const PracticeQuestionDataContext = createContext<{
  practiceQuestionList: PracticeQuestionListRes | null;
  practiceQuestionDetail: PracticeQuestionDto | null;
  practiceQuestionClient: PracticeQuestionDto[] | null;
}>({
  practiceQuestionList: null,
  practiceQuestionDetail: null,
  practiceQuestionClient: [],
});

/** 📌 Context chứa các actions */
const PracticeQuestionActionsContext = createContext<{
  updatePracticeQuestionList: (data: PracticeQuestionListRes) => void;
  updatePracticeQuestionDetail: (data: PracticeQuestionDto) => void;
  updatePracticeQuestionClient: (data: PracticeQuestionDto[]) => void;
}>({
  updatePracticeQuestionList: () => { },
  updatePracticeQuestionDetail: () => { },
  updatePracticeQuestionClient: () => { },
});

/** 📌 Provider tổng hợp */
export const PracticeQuestionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceQuestionList, setPracticeQuestionList] = useState<PracticeQuestionListRes | null>(null);
  const [practiceQuestionDetail, setPracticeQuestionDetail] = useState<PracticeQuestionDto | null>(null);
  const [practiceQuestionClient, setPracticeQuestionClient] = useState<PracticeQuestionDto[]>([]);

  return (
    <PracticeQuestionDataContext.Provider value={{ practiceQuestionList, practiceQuestionDetail, practiceQuestionClient }}>
      <PracticeQuestionActionsContext.Provider
        value={{
          updatePracticeQuestionList: setPracticeQuestionList,
          updatePracticeQuestionDetail: setPracticeQuestionDetail,
          updatePracticeQuestionClient: setPracticeQuestionClient,
        }}
      >
        {children}
      </PracticeQuestionActionsContext.Provider>
    </PracticeQuestionDataContext.Provider>
  );
};

/** 📌 Custom hooks */
export const usePracticeQuestionDataContext = () => useContext(PracticeQuestionDataContext);
export const usePracticeQuestionActionsContext = () => useContext(PracticeQuestionActionsContext);
