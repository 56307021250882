import { PracticeScoreDto, PracticeScoreListRes } from "models/classes/practiceScore.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** 📌 Context chứa dữ liệu */
const PracticeScoreDataContext = createContext<{
  practiceScoreList: PracticeScoreListRes | null;
  practiceScoreDetail: PracticeScoreDto | null;
}>({
  practiceScoreList: null,
  practiceScoreDetail: null,
});

/** 📌 Context chứa các actions */
const PracticeScoreActionsContext = createContext<{
  updatePracticeScoreList: (data: PracticeScoreListRes) => void;
  updatePracticeScoreDetail: (data: PracticeScoreDto | null) => void;
}>({
  updatePracticeScoreList: () => { },
  updatePracticeScoreDetail: () => { },
});

/** 📌 Provider tổng hợp */
export const PracticeScoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceScoreList, setPracticeScoreList] = useState<PracticeScoreListRes | null>(null);
  const [practiceScoreDetail, setPracticeScoreDetail] = useState<PracticeScoreDto | null>(null);

  return (
    <PracticeScoreDataContext.Provider value={{ practiceScoreList, practiceScoreDetail }}>
      <PracticeScoreActionsContext.Provider
        value={{
          updatePracticeScoreList: setPracticeScoreList,
          updatePracticeScoreDetail: setPracticeScoreDetail,
        }}
      >
        {children}
      </PracticeScoreActionsContext.Provider>
    </PracticeScoreDataContext.Provider>
  );
};

/** 📌 Custom hooks */
export const usePracticeScoreDataContext = () => useContext(PracticeScoreDataContext);
export const usePracticeScoreActionsContext = () => useContext(PracticeScoreActionsContext);
