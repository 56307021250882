import { PracticeAnswerDto, PracticeAnswerListRes } from "models/classes/practiceAnswer.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

/** Context chứa dữ liệu */
const PracticeAnswerDataContext = createContext<{
  practiceAnswerList: PracticeAnswerListRes | null;
  practiceAnswerDetail: PracticeAnswerDto | null;
}>({
  practiceAnswerList: null,
  practiceAnswerDetail: null,
});

/** Context chứa các hàm cập nhật */
const PracticeAnswerActionsContext = createContext<{
  updatePracticeAnswerList: (data: PracticeAnswerListRes) => void;
  updatePracticeAnswerDetail: (data: PracticeAnswerDto) => void;
}>({
  updatePracticeAnswerList: () => { },
  updatePracticeAnswerDetail: () => { },
});

/** Provider tổng hợp */
export const PracticeAnswerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceAnswerList, setPracticeAnswerList] = useState<PracticeAnswerListRes | null>(null);
  const [practiceAnswerDetail, setPracticeAnswerDetail] = useState<PracticeAnswerDto | null>(null);

  return (
    <PracticeAnswerDataContext.Provider value={{ practiceAnswerList, practiceAnswerDetail }}>
      <PracticeAnswerActionsContext.Provider value={{
        updatePracticeAnswerList: setPracticeAnswerList,
        updatePracticeAnswerDetail: setPracticeAnswerDetail
      }}>
        {children}
      </PracticeAnswerActionsContext.Provider>
    </PracticeAnswerDataContext.Provider>
  );
};

/** Custom hooks */
export const usePracticeAnswerDataContext = () => useContext(PracticeAnswerDataContext);
export const usePracticeAnswerActionsContext = () => useContext(PracticeAnswerActionsContext);
