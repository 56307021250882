import { Suspense, lazy, useEffect, useState } from "react";
import styled from "styled-components";

// COMPONENTS
import { Stack } from "@mui/material";
import LoadingNinedev from "components/progressBar/LoadingNinedev";

// LAZY
const BannerDashboard = lazy(() => import("./sections/BannerDashboard"));
const CourseCategory = lazy(() => import("./sections/CourseCategory"));
const FeedbackHome = lazy(() => import("./sections/FeedbackHome"));
const CourseFavorite = lazy(() => import("./sections/CourseFavorite"));
const BlogNew = lazy(() => import("./sections/BlogNew"));
const RoadMap = lazy(() => import("./sections/RoadMap"));
const InterviewDashboard = lazy(() => import("./sections/InterviewDashboard"));
const ResizeImage = lazy(() => import("./sections/ResizeImage"));

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <LoadingNinedev isLoading={isLoading}>
      <WrapperStyled
        padding={{ xs: '2rem 1rem', sm: '3rem 2rem' }}
        width={{ xs: 'auto', md: '100%' }}
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '3rem', sm: '5rem' }}
        position={{ xs: 'relative' }}
      >
        <Suspense>
          <BannerDashboard />
          <CourseCategory />
          <RoadMap />
          <FeedbackHome />
          <InterviewDashboard />
          <ResizeImage />
          <Stack
            width={{ xs: '-webkit-fill-available' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            columnGap={{ xs: '3rem' }}
            rowGap={{ xs: '3rem' }}
          >
            <CourseFavorite />
            <BlogNew />
          </Stack>
        </Suspense>
      </WrapperStyled>
    </LoadingNinedev>
  )
};

const WrapperStyled = styled(Stack)`
`;

export default DashboardPage;
