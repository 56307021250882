import { isEmpty } from "lodash";
import {
  fetchPracticeQuestionClientApi,
  fetchPracticeQuestionCreateApi,
  fetchPracticeQuestionDeleteApi,
  fetchPracticeQuestionDetailApi,
  fetchPracticeQuestionListApi,
  fetchPracticeQuestionUpdateApi,
} from "./practiceQuestionFetch";
import { FetchPracticeQuestionParams } from "./practiceQuestionParam";
import {
  PracticeQuestionData,
  PracticeQuestionDto,
} from "models/classes/practiceQuestion.class";

export const actionPracticeQuestionListApi = async ({
  ...params
}: FetchPracticeQuestionParams) => {
  try {
    const { data } = await fetchPracticeQuestionListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: PracticeQuestionDto) =>
          new PracticeQuestionDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeQuestionCreateApi = async (
  body: PracticeQuestionData
) => {
  try {
    const { data } = await fetchPracticeQuestionCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeQuestionUpdateApi = async (
  id: number,
  body: PracticeQuestionData
) => {
  try {
    const { data } = await fetchPracticeQuestionUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeQuestionDetailApi = async ({
  ...params
}: FetchPracticeQuestionParams) => {
  try {
    const { data } = await fetchPracticeQuestionDetailApi(params);
    if (!isEmpty(data)) {
      return new PracticeQuestionDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeQuestionDeleteApi = async ({
  ...params
}: FetchPracticeQuestionParams) => {
  try {
    const { data } = await fetchPracticeQuestionDeleteApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeQuestionClientApi = async ({
  ...params
}: FetchPracticeQuestionParams) => {
  try {
    const { data } = await fetchPracticeQuestionClientApi(params);
    if (!isEmpty(data)) {
      return data.map((item: PracticeQuestionDto) =>
        new PracticeQuestionDto().fromPayload(item)
      );
    }
  } catch (error) {
    console.log(error);
  }
};
