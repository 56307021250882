import {
  CourseSectionByCourseDetailDto,
  CourseSectionListRes
} from "models/classes/courseSection.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

/** Context chứa từng phần riêng biệt */
const CourseSectionListContext = createContext<CourseSectionListRes | null>(null);
const CourseSectionContext = createContext<CourseSectionByCourseDetailDto | null>(null);
const CourseSectionAllContext = createContext<CourseSectionByCourseDetailDto[]>([]);

/** Context chứa hàm cập nhật */
const UpdateCourseSectionListContext = createContext<(data: CourseSectionListRes) => void>(() => { });
const UpdateCourseSectionContext = createContext<(data: CourseSectionByCourseDetailDto) => void>(() => { });
const UpdateCourseSectionAllContext = createContext<(data: CourseSectionByCourseDetailDto[]) => void>(() => { });

export const CourseSectionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseSectionListRes, setCourseSectionListRes] = useState<CourseSectionListRes | null>(null);
  const [section, setSection] = useState<CourseSectionByCourseDetailDto | null>(null);
  const [sectionAll, setSectionAll] = useState<CourseSectionByCourseDetailDto[]>([]);

  return (
    <CourseSectionListContext.Provider value={courseSectionListRes}>
      <UpdateCourseSectionListContext.Provider value={setCourseSectionListRes}>
        <CourseSectionContext.Provider value={section}>
          <UpdateCourseSectionContext.Provider value={setSection}>
            <CourseSectionAllContext.Provider value={sectionAll}>
              <UpdateCourseSectionAllContext.Provider value={setSectionAll}>
                {children}
              </UpdateCourseSectionAllContext.Provider>
            </CourseSectionAllContext.Provider>
          </UpdateCourseSectionContext.Provider>
        </CourseSectionContext.Provider>
      </UpdateCourseSectionListContext.Provider>
    </CourseSectionListContext.Provider>
  );
};

/** Custom hooks */
export const useCourseSectionListContext = () => useContext(CourseSectionListContext);
export const useUpdateCourseSectionListContext = () => useContext(UpdateCourseSectionListContext);

export const useCourseSectionContext = () => useContext(CourseSectionContext);
export const useUpdateCourseSectionContext = () => useContext(UpdateCourseSectionContext);

export const useCourseSectionAllContext = () => useContext(CourseSectionAllContext);
export const useUpdateCourseSectionAllContext = () => useContext(UpdateCourseSectionAllContext);
