import { CourseLessonDto, CourseLessonInfoDto, CourseLessonListRes } from "models/classes/courseLesson.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

/** Context quản lý danh sách bài học */
const CourseLessonListContext = createContext<{
  lessons: CourseLessonInfoDto[];
  lessonListRes: CourseLessonListRes | null;
  updateLessons: (lessons: CourseLessonInfoDto[]) => void;
  updateLessonListRes: (lessonList: CourseLessonListRes) => void;
}>({
  lessons: [],
  lessonListRes: null,
  updateLessons: () => { },
  updateLessonListRes: () => { },
});

/** Context quản lý chi tiết bài học */
const CourseLessonDetailContext = createContext<{
  lesson: CourseLessonInfoDto | null;
  lessonDetailByAdmin: CourseLessonDto | null;
  updateLesson: (lesson: CourseLessonInfoDto) => void;
  updateLessonDetailByAdmin: (lesson: CourseLessonDto) => void;
}>({
  lesson: null,
  lessonDetailByAdmin: null,
  updateLesson: () => { },
  updateLessonDetailByAdmin: () => { },
});

/** Provider tổng hợp */
export const CourseLessonProvider = ({ children }: PropsWithChildren<{}>) => {
  const [lessons, setLessons] = useState<CourseLessonInfoDto[]>([]);
  const [lessonListRes, setLessonListRes] = useState<CourseLessonListRes | null>(null);
  const [lesson, setLesson] = useState<CourseLessonInfoDto | null>(null);
  const [lessonDetailByAdmin, setLessonDetailByAdmin] = useState<CourseLessonDto | null>(null);

  return (
    <CourseLessonListContext.Provider value={{ lessons, lessonListRes, updateLessons: setLessons, updateLessonListRes: setLessonListRes }}>
      <CourseLessonDetailContext.Provider value={{ lesson, lessonDetailByAdmin, updateLesson: setLesson, updateLessonDetailByAdmin: setLessonDetailByAdmin }}>
        {children}
      </CourseLessonDetailContext.Provider>
    </CourseLessonListContext.Provider>
  );
};

/** Custom hooks */
export const useCourseLessonListContext = () => useContext(CourseLessonListContext);
export const useCourseLessonDetailContext = () => useContext(CourseLessonDetailContext);
