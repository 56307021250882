import { isEmpty } from "lodash";
import { PracticeScoreDto } from "models/classes/practiceScore.class";
import {
  fetchPracticeScoreDeleteByIdApi,
  fetchPracticeScoreDetailClientApi,
  fetchPracticeScoreFinishApi,
  fetchPracticeScoreListApi,
  fetchPracticeScoreListClientApi,
  fetchPracticeScoreTopRankApi,
} from "./practiceScoreFetch";
import {
  FetchPracticeScoreParams,
  PracticeScoreFinishData,
} from "./practiceScoreParam";

export const actionPracticeScoreListApi = async ({
  ...params
}: FetchPracticeScoreParams) => {
  try {
    const { data } = await fetchPracticeScoreListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: PracticeScoreDto) =>
          new PracticeScoreDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeScoreListClientApi = async ({
  ...params
}: FetchPracticeScoreParams) => {
  try {
    const { data } = await fetchPracticeScoreListClientApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: PracticeScoreDto) =>
          new PracticeScoreDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeScoreTopRankApi = async ({
  ...params
}: FetchPracticeScoreParams) => {
  try {
    const { data } = await fetchPracticeScoreTopRankApi(params);
    if (!isEmpty(data)) {
      return data.map((item: PracticeScoreDto) =>
        new PracticeScoreDto().fromPayload(item)
      );
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeScoreFinishApi = async (
  body: PracticeScoreFinishData
) => {
  try {
    const { data } = await fetchPracticeScoreFinishApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeScoreDetailClientApi = async ({
  ...params
}: FetchPracticeScoreParams) => {
  try {
    const { data } = await fetchPracticeScoreDetailClientApi(params);
    if (!isEmpty(data)) {
      return new PracticeScoreDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeScoreDeleteApi = async ({
  ...params
}: FetchPracticeScoreParams) => {
  try {
    const { data } = await fetchPracticeScoreDeleteByIdApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};
