import { CourseBuyerListByAdminRes, CourseBuyerListByStudentRes } from "models/classes/courseBuyer.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

/** Context cho danh sách người mua khóa học theo student */
const CourseBuyerListByStudentContext = createContext<{
  courseBuyerListByStudent: CourseBuyerListByStudentRes | null;
  updateCourseBuyerListStudent: (courseBuyer: CourseBuyerListByStudentRes) => void;
}>({
  courseBuyerListByStudent: null,
  updateCourseBuyerListStudent: () => { },
});

/** Context cho danh sách người mua khóa học theo admin */
const CourseBuyerListByAdminContext = createContext<{
  courseBuyerListByAdminRes: CourseBuyerListByAdminRes | null;
  updateCourseBuyerListAdminRes: (courseBuyer: CourseBuyerListByAdminRes) => void;
}>({
  courseBuyerListByAdminRes: null,
  updateCourseBuyerListAdminRes: () => { },
});

/** Provider tổng hợp */
export const CourseBuyerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseBuyerListByStudent, setCourseBuyerListByStudent] = useState<CourseBuyerListByStudentRes | null>(null);
  const [courseBuyerListByAdminRes, setCourseBuyerListByAdminRes] = useState<CourseBuyerListByAdminRes | null>(null);

  const updateCourseBuyerListStudent = (courseBuyer: CourseBuyerListByStudentRes) => {
    setCourseBuyerListByStudent(courseBuyer);
  };

  const updateCourseBuyerListAdminRes = (courseBuyer: CourseBuyerListByAdminRes) => {
    setCourseBuyerListByAdminRes(courseBuyer);
  };

  return (
    <CourseBuyerListByStudentContext.Provider value={{ courseBuyerListByStudent, updateCourseBuyerListStudent }}>
      <CourseBuyerListByAdminContext.Provider value={{ courseBuyerListByAdminRes, updateCourseBuyerListAdminRes }}>
        {children}
      </CourseBuyerListByAdminContext.Provider>
    </CourseBuyerListByStudentContext.Provider>
  );
};

/** Custom hooks */
export const useCourseBuyerListByStudentContext = () => useContext(CourseBuyerListByStudentContext);
export const useCourseBuyerListByAdminContext = () => useContext(CourseBuyerListByAdminContext);
